import { Timestamp } from "firebase/firestore";

const WEEK_PLAN = {
    week: "2023-W45",

    programId: "",
    trainerId: "",
    weekNo: 2,

    created_at: Timestamp.fromDate(new Date()),

    // the days in the workout
    days: [1, 2, 4, 6],

    // the exercises
    "exercises": {
        111: {
            name: "Squat",
            exerciseId: 275,
            intensity: 70,
            sets: 3,
            reps: 6,
            tempo: "101",
            rest: 180,
            note: "Belted",
            progression: {
                0: {
                    reps: 6
                },
                1: {
                    reps: 2
                },
                2: {
                    reps: 3
                }
            }
        },
        222: {
            name: "Bench",
            exerciseId: 21,
            intensity: 70,
            sets: 4,
            reps: 6,
            tempo: "111",
            rest: 180,
            completed_at: {

            },
            "feedbacks": {
                0: {
                    "weight": 22,
                    "completed_at": {
                        "seconds": 1212121
                    }
                }
            }
        },
        333: {
            name: "Overhead Press",
            exerciseId: 187,
            sets: 3,
            reps: 8,
            rpe: 10,
            tempo: "101",
            rest: 120,
            "feedbacks": {
                0: {
                    "weight": 43,
                    "completed_at": {
                        "seconds": 1212121
                    }
                },
                1: {
                    "weight": 54,
                    "failed": true,
                    "completed_at": {
                        "seconds": 1212121
                    }
                }
            }
        },
        444: {
            name: "Bent Over Row",
            exerciseId: 26,
            sets: 3,
            reps: 12,
            rpe: 10,
            tempo: "101",
            rest: 90
        },
        555: {
            name: "GHR Back Extensions",
            sets: 4,
            reps: 12,
            rpe: 10,
            tempo: "111",
            rest: 60
        },
        11: {
            name: "Deadlift",
            exerciseId: 71,
            intensity: 70,
            sets: 4,
            reps: 7,
            tempo: "101",
            rest: 180,
            trainerNote: "Two inches above parallel on concentric, 1 second."
        },
        22: {
            name: "Bench",
            exerciseId: 21,
            intensity: 60,
            sets: 3,
            reps: 6,
            tempo: "131",
            rest: 180
        },
        33: {
            name: "Squat",
            exerciseId: 17,
            sets: 3,
            reps: 5,
            rpe: 8,
            tempo: "121",
            rest: 45
        },
        44: {
            name: "Row",
            exerciseId: 224,
            rpe: 10,
            sets: 5,
            reps: 8,
            tempo: "101",
            rest: 90
        },
        811: {
            name: "Squat",
            trainerNote: "Pin Squat",
            exerciseId: 275,
            intensity: 70,
            sets: 3,
            reps: 5,
            tempo: "111",
            rest: 120
        },
        282: {
            name: "2 Board Press",
            rpe: 8,
            sets: 3,
            reps: 5,
            tempo: "101",
            rest: 60
        },
        833: {
            name: "1 Arm Dumbbell Row",
            rpe: 10,
            sets: 5,
            reps: 10,
            tempo: "111",
            rest: 90
        },
        484: {
            name: "Bird Dogs",
            note: "Reps per side",
            rpe: 10,
            sets: 3,
            reps: 6,
            tempo: "111",
            rest: 90
        },
        8191: {
            name: "Deadlift",
            note: "Pause Barely Off Floor",
            exerciseId: 71,
            intensity: 65,
            sets: 3,
            reps: 5,
            tempo: "121",
            rest: 120
        },
        2982: {
            name: "Bench",
            note: "Touch & Go",
            exerciseId: 21,
            intensity: 65,
            sets: 4,
            reps: 10,
            tempo: "101",
            rest: 60
        },
        8933: {
            name: "Stiff Leg Dead Lift",
            exerciseId: 282,
            intensity: 43,
            sets: 3,
            reps: 8,
            rpe: 10,
            tempo: "101",
            rest: 180
        },
        4984: {
            name: "Vertical Pull Of Choice",
            sets: 4,
            reps: 10,
            rpe: 10,
            tempo: "101",
            rest: 90
        },
        498224: {
            name: "Tricep Movement Of Choice",
            sets: 4,
            reps: 10,
            rpe: 10,
            tempo: "101",
            rest: 90
        },
    },

    // the program
    dailySchedules: {
        0: {
            readiness: {
                sleep: 1,
                stress: 4,
                fatigue: 5,
                diet: 3,
                soreness: 2
            },
            exerciseIds: [111, 222, 333, 444, 555]
        },
        1: {
            readiness: {
                stress: 1,
                diet: 3,
                soreness: 2
            },
            exerciseIds: [11, 22, 33, 44]
        },
        2: {
            exerciseIds: [811, 282, 833, 484]
        },
        3: {
            exerciseIds: [8191, 2982, 8933, 4984, 498224]
        }
    }
};

export default WEEK_PLAN;